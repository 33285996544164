import React from "react";

import recent1 from "../images/recent-1.jpg";
import recent2 from "../images/recent-2.jpg";
import recent3 from "../images/recent-3.jpg";
import recent4 from "../images/recent-4.jpg";

class RecentProjects extends React.Component {
  render() {
    return (
      <div className="w-3/4 rounded bg-white p-8 -mt-16 mb-0 mx-auto text-center">
        <h3 className="section-header">Recent Projects</h3>

        <div className="lg:flex justify-between items-center mt-12">
          <div className="overflow-hidden rounded w-full h-48 block mb-3 lg:mb-0 lg:h-56 lg:w-1/4 p-2">
            <img src={recent1} alt="electrical installation" className="min-h-48 w-full" />
          </div>
          <div className="overflow-hidden rounded w-full h-48 block mb-3 lg:mb-0 lg:h-56 lg:w-1/4 p-2">
            <img src={recent2} alt="electrical installation" className="min-h-48 w-full" />
          </div>
          <div className="overflow-hidden rounded w-full h-48 block mb-3 lg:mb-0 lg:h-56 lg:w-1/4 p-2">
            <img src={recent3} alt="electrical installation" className="min-h-48 w-full" />
          </div>
          <div className="overflow-hidden rounded w-full h-48 block lg:mb-0 lg:h-56 lg:w-1/4 p-2">
            <img src={recent4} alt="electrical installation" className="min-h-48 w-full" />
          </div>
        </div>
      </div>
    )
  }
}

export default RecentProjects;