import React from "react"

const HiddenFormForNetlify = (props) => {
  return (
    <form className="mt-8 hidden" id="request-quote-modal-form" name="request-quote-modal-form" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action={typeof window !== 'undefined' && window.location.href.concat("?form-success=true")}>
      <input type="text" className="w-full bg-gray-200 p-4 rounded" name="name" />
      <input type="email" id="contact-email" className="w-full bg-gray-200 p-4 rounded" name="email" />
      <input type="text" id="contact-phone" className="w-full bg-gray-200 p-4 rounded" name="phone" />
      <input type="text" id="contact-location" className="w-full bg-gray-200 p-4 rounded" name="project_location" />
      <textarea className="w-full bg-gray-200 p-4 rounded" id="contact-details" name="project_details" />
      <input type="hidden" name="form-name" id="form-name" value="request-quote-modal-form" />
      <input type="hidden" name="bot-field" id="bot-field" />
      <input type="submit" className="bg-maroon-lighter hover:bg-maroon text-white rounded-sm py-4 px-12" value="Send message" />
    </form>
  )
}

export default HiddenFormForNetlify;