import React from "react";
import SmallGoogleMap from "./small-google-map";

const Footer = (props) => {
  return (
    <div id="contact">
      <div className="bg-gray-800 py-24 px-6 md:flex">
        <div className="w-full md:w-2/3 mr-12">
          <h3 className="text-xl font-semibold text-gray-100 mb-4">Contact us</h3>
          <p className="text-white mt-4"><span className="text-gray-500 font-semibold">Address:</span> P.O. Box 210, Jarrettsville, MD 21084</p>
          <p className="text-white mt-4"><span className="text-gray-500 font-semibold">Phone:</span> 410-557-8557</p>
          <p className="text-white mt-4"><span className="text-gray-500 font-semibold">Email:</span> office@wyattelectricinc.com</p>

          <form className="w-full mb-12 lg:mb-0 mt-8" id="generic-footer-form" name="generic-footer-form" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action={typeof window !== 'undefined' && window.location.href.concat("?form-success=true")}>
            <div className="mb-3">
              <input type="text" className="bg-gray-900 text-gray-400 w-full max-w-md rounded-sm p-4" placeholder="Name" name="name" required />
            </div>
            <div className="mb-3">
              <input type="email" className="bg-gray-900 text-gray-400 w-full max-w-md rounded-sm p-4" placeholder="Email" name="email" required />
            </div>
            <div className="mb-3">
              <input type="text" className="bg-gray-900 text-gray-400 w-full max-w-md rounded-sm p-4" placeholder="Phone number" name="phone_number" />
            </div>
            <div className="mb-3">
              <textarea className="bg-gray-900 text-gray-400 rounded-sm p-4 w-full max-w-xl" name="message" placeholder="Message" rows="4" required />
            </div>

            <input type="hidden" name="form-name" id="form-name" value="generic-footer-form" />
            <input type="hidden" name="bot-field" id="bot-field" />

            <input type="submit" className="cursor-pointer bg-gray-400 hover:bg-gray-100 text-gray-700 rounded-sm py-4 px-12 shadow" value="Send message" />
          </form>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold text-gray-100 mb-4">Service areas</h3>

          <div className="mb-4">
            <SmallGoogleMap mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d197400.1339242074!2d-76.67177403426992!3d39.370268146369725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1574253809869!5m2!1sen!2sus" />
            <span className="text-xs text-gray-200">Servicing Baltimore City & County, Harford County</span>
          </div>

          <div className="mt-10">
            <SmallGoogleMap mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d199736.41636069285!2d-75.25888729103012!3d38.526084894061846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1574254002862!5m2!1sen!2sus" />
            <span className="text-xs text-gray-200">Servicing Lewes, Rehoboth, Bethany, Fenwick, and Ocean City</span>
          </div>

          {/* <h3 className="text-xl font-semibold text-gray-100 mb-4 mt-10">Follow us</h3>
          <div className="flex items-center">
            <div className="h-12 w-12 rounded-full bg-gray-700 mr-2"></div>
            <div className="h-12 w-12 rounded-full bg-gray-700 mr-2"></div>
            <div className="h-12 w-12 rounded-full bg-gray-700 mr-2"></div>
            <div className="h-12 w-12 rounded-full bg-gray-700 mr-2"></div>
            <div className="h-12 w-12 rounded-full bg-gray-700"></div>
          </div> */}
        </div>
      </div>

      <div className="bg-gray-900 py-12 px-6 md:flex text-white">
        <div className="md:w-1/2">
          <p><span className="font-bold">Wyatt</span> Electric <span className="font-hairline">Inc.</span><br /><span className="text-gray-500">&copy; Copyright 2019, All rights reserved</span></p>
        </div>

        <div className="mt-8 md:mt-0 md:w-1/2 md:text-right">
          <p className="text-gray-700">
            <span className="text-xs uppercase">Site by</span><br />
            <span className="text-gray-500 hover:text-white cursor-pointer"><span className="font-bold">Riptide</span>Growth</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer;