import React from "react";
import { Link } from "gatsby";
import logo from "../images/we-logo.svg";
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll';

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  navLinkClasses() {
    if(this.state.expanded) {
      return "w-full lg:block flex-grow lg:flex lg:items-stretch lg:w-auto"
    } else {
      return "hidden w-full lg:block flex-grow lg:flex lg:items-stretch lg:w-auto"
    }
  }

  componentDidMount() {
    var scroll = new SmoothScroll('a[href*="#"]');
  }

  render() {
    return (
      <nav className="no-default-border flex items-center justify-between flex-wrap bg-white pl-6 py-6 lg:py-0">
        <div className="flex items-center flex-shrink-0 text-gray-800 hover:text-gray-900 mr-6 lg:mr-8">
          <Link to="/" className="flex items-center">
            <img src={logo} className="max-w-full max-h-full h-4 inline mr-1" />
            <span className="font-bold">Wyatt</span>Electric
          </Link>
        </div>
        <div className="block lg:hidden">
          <button onClick={() => this.setState({expanded: !this.state.expanded})} className="flex items-center px-3 py-2 border rounded text-gray border-gray hover:bg-gray-100">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div className={this.navLinkClasses()}>
          <div className="text-sm lg:flex-grow">
            <Link to="/residential" className="text-grayish hover:text-gray-900 block mt-4 lg:inline-block lg:mt-0 hover:text-gray mr-4 lg:mr-8 lg:py-6">
              Residential
            </Link>
            <Link to="/commercial" className="text-grayish hover:text-gray-900 block mt-4 lg:inline-block lg:mt-0 hover:text-gray mr-4 lg:mr-8 lg:py-6">
              Commercial
            </Link>
            <Link to="/smart-home-installation" className="text-grayish hover:text-gray-900 block mt-4 lg:inline-block lg:mt-0 hover:text-gray mr-4 lg:mr-8 lg:py-6">
              Smart Home
            </Link>
            <Link to="/about" className="text-grayish hover:text-gray-900 block mt-4 lg:inline-block lg:mt-0 hover:text-gray mr-4 lg:mr-8 lg:py-6">
              About
            </Link>
            <a href="#contact" className="text-grayish hover:text-gray-900 block mt-4 lg:inline-block lg:mt-0 hover:text-gray lg:py-6">
              Contact
            </a>
          </div>
          <div className="flex">
            <a href="tel:4105578557" className="bg-maroon hover:bg-maroon-darker rounded-sm lg:rounded-none flex items-center text-sm px-10 py-2 leading-none border text-red-100 border-white mt-4 lg:mt-0">Call to request an estimate</a>
            {/* <button onClick={this.props.displayModal} className="bg-maroon hover:bg-maroon-darker rounded-sm lg:rounded-none flex items-center text-sm px-10 py-2 leading-none border text-red-100 border-white mt-4 lg:mt-0">Request an estimate</button> */}
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar;