import React from "react"

const SmallGoogleMap = ({mapSrc}) => {
  return (
    <div className="rounded overflow-hidden">
      <iframe className="grayscale-map w-full h-36" src={mapSrc} frameborder="0" style={{border: 0 }} allowfullscreen=""></iframe>
    </div>
  )
}

export default SmallGoogleMap;