import React from "react";
import heroImg from "../images/hero-background.jpg";

const Hero = (props) => {
  return (
    <div className="pt-32 pb-48 px-6" style={{backgroundImage: `url('${heroImg}')`, backgroundSize: "cover", backgroundPosition: "center center"}}>
      <div className="max-w-2xl">
        <h1 className="text-white text-3xl font-bold mb-4">The Premier Electrical Contractor in the Baltimore Area</h1>
        <p className="text-gray-300 font-sm">Wyatt Electric has been serving the high-end residential markets in the Baltimore Area and Delaware Beaches for over 30 years. With top notch experience and customer service, we’re here to help you for the lifetime of your home.</p>

        <div className="mt-10">
          <a href="tel:4105578557" className="w-full md:w-auto bg-maroon hover:bg-maroon-darker text-red-100 px-8 py-4 rounded-sm shadow">Call to request an estimate</a>
          {/* <button onClick={props.displayModal} className="w-full md:w-auto bg-maroon hover:bg-maroon-darker text-red-100 px-8 py-4 rounded-sm shadow">Request an estimate</button> */}
          <a href="#services" className="block text-center md:inline-block w-full md:w-auto mt-3 md:mt-0 px-8 py-4 bg-gray-800 hover:bg-gray-900 text-gray-100 rounded-sm md:ml-4">Learn about our services</a>
        </div>
      </div>
    </div>
  )
}

export default Hero;