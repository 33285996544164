import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Navbar from "./navbar";
import Hero from "./hero"
import RecentProjects from "./recent-projects";
import ContactModal from "./contact-modal";
import HiddenFormForNetlify from "./hidden-form-for-netlify";
import Footer from "./footer";

import camoImg from "../images/camobackground.png";
import esgrLogo from "../images/esgr-award.jpg";

import lutronProLogo from "../images/lutron-pro-logo.png";
import squaredLogo from "../images/logos/squared-logo.png";
import junoLogo from "../images/logos/juno-logo.png";


class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayContactModal: false,
      displayThanks: typeof window !== 'undefined' && new URLSearchParams(window.location.search).get("form-success")
    }
  }

  displayModal() {
    this.setState({displayContactModal: true})
  }

  hideModal() {
    this.setState({displayContactModal: false})
  }

  render() {
    const modal = (this.state.displayContactModal || this.state.displayThanks) ? <ContactModal hideModal={this.hideModal.bind(this)} displayThanks={this.state.displayThanks} /> : <HiddenFormForNetlify /> ;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div>

              {this.props.children}

              <Navbar displayModal={this.displayModal.bind(this)} />
              <Hero displayModal={this.displayModal.bind(this)} />
              <RecentProjects />
              
              <div id="services" className="bg-gray-100 py-24 px-6 text-center">
                <h3 className="section-header">Services</h3>

                <div className="mt-12 lg:flex justify-between items-stretch">
                  <div className="lg:w-1/4 w-full mb-10 lg:mb-0 px-4 lg:px-8 lg:flex lg:flex-col">
                    <h5 className="section-header section-header--small">Residential repair</h5>
                    <p className="text-gray-700 lg:flex-grow">We can fix any electrical issue in your home from troubleshooting to installing new fixtures, smart home devices, and more.</p>
                    <Link to="/residential" className="text-maroon mt-4 block hover:underline">Learn more</Link>
                  </div>

                  <div className="lg:w-1/4 w-full mb-10 lg:mb-0 px-4 lg:px-8 lg:flex lg:flex-col">
                    <h5 className="section-header section-header--small">New residential construction</h5>
                    <p className="text-gray-700 lg:flex-grow">Building a new house or adding an addition? We can do it all and help you choose the best products for your project.</p>
                    <Link to="/residential" className="text-maroon mt-4 block hover:underline">Learn more</Link>
                  </div>

                  <div className="lg:w-1/4 w-full mb-10 lg:mb-0 px-4 lg:px-8 lg:flex lg:flex-col">
                    <h5 className="section-header section-header--small">Smart home</h5>
                    <p className="text-gray-700 lg:flex-grow">We can install smart home controls to allow you to control lighting, temperature, and smart devices throughout your home or office.</p>
                    <Link to="/smart-home-installation" className="text-maroon mt-4 block hover:underline">Learn more</Link>
                  </div>

                  <div className="lg:w-1/4 w-full px-4 lg:px-8 lg:flex lg:flex-col">
                    <h5 className="section-header section-header--small">Commercial</h5>
                    <p className="text-gray-700 lg:flex-grow">We specialize in office & retail space build-outs, all types of lighting, and standard troubleshooting and maintenance.</p>
                    <a href="#" className="text-maroon mt-4 block hover:underline">Learn more</a>
                  </div>
                </div>
              </div>

              <div className="py-24 px-6" style={{backgroundImage: `url(${camoImg})`, backgroundSize: "cover"}}>
                <div className="lg:flex lg:w-3/4 lg:mx-auto">
                  <div className="w-full lg:w-1/3 lg:mr-12">
                    <div className="bg-white p-8 rounded">
                      <img src={esgrLogo} className="max-w-full" />
                    </div>
                  </div>
                  <div className="w-full lg:w-2/3 mt-10 lg:mt-0">
                    <h3 className="section-header text-xl">Above & Beyond Award</h3>
                    <p className="mt-3">Wyatt Electric supports our military and was honored with the <em>Above and Beyond Award</em>. This award is given to recognize employers at a local level who exceed legal requirements by providing Guard and Reserve employees with various benefits.</p>
                    <p className="mt-3">The <em>Above and Beyond</em> award is only given to a select number of employers and Wyatt Electric is extremely proud to be one of them.</p>
                  </div>
                </div>
              </div>

              <div className="py-24 px-6 bg-white">
                <div className="lg:w-3/4 lg:mx-auto text-center">
                  <h3 className="section-header">We only use the most trusted brands</h3>
                  <p className="text-sm text-gray-600 max-w-lg mx-auto mt-2">Wyatt Electric has built strong relationships with the best manufacturers and vendors which helps us provide unparalleled service to you.</p>

                  <div className="lg:flex items-center justify-around mt-12">
                    <div className="lg:flex items-center justify-center lg:px-4 lg:w-1/3 max-w-sm mx-auto">
                      <img src={squaredLogo} alt="squared logo" className="max-w-full mb-16 mx-auto lg:max-w-none lg:mb-0 lg:h-8" />
                    </div>
                    <div className="lg:flex items-center justify-center lg:px-4 lg:w-1/3 max-w-sm mx-auto">
                      <Link to="/smart-home-installation">
                        <img src={lutronProLogo} alt="lutron pro" className="max-w-full mb-16 mx-auto lg:max-w-none lg:mb-0 lg:h-24" />
                      </Link>
                    </div>
                    <div className="lg:flex items-center justify-center lg:px-4 lg:w-1/3 max-w-sm mx-auto">
                      <img src={junoLogo} alt="juno logo" className="max-w-full lg:max-w-none mx-auto lg:h-24" />
                    </div>
                  </div>
                </div>
              </div>

              <Footer />

              {modal}
          </div>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
